import React, { ReactNode } from 'react';
import NextLink from 'next/link';
import classNames from 'classnames';
import styles from './styles.module.scss';
export interface LinkProps {
  className?: string;
  to?: string;
  variant?: 'alpha' | 'grey';
  children?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  [key: string]: any;
}
const Link: React.FC<LinkProps> = ({
  className,
  children,
  to,
  variant = 'alpha',
  onClick,
  ...props
}) => {
  const componentClasses = classNames(styles.link, {
    [styles.linkGrey]: variant === 'grey'
  }, className);
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      onClick(e);
    }
  };
  if (to) {
    return <NextLink className={componentClasses} href={to} onClick={handleClick} {...props}>
        {children}
      </NextLink>;
  }
  return <a className={componentClasses} onClick={onClick} {...props} data-sentry-component="Link" data-sentry-source-file="index.tsx">
      {children}
    </a>;
};
export default Link;