import { useEffect } from 'react';
import { useOverlayContext } from '@afs/components/OverlayContext';
import canUseDOM from '../utils/canUseDom';
interface OverlayItem {
  id: string;
  isActive: boolean;
  bodyClassName?: 'no-scroll' | 'no-scroll-and-hidden';
}
const useBodyLockWhenOverlayIsActive = ({
  overlayUniqueId,
  isActive,
  bodyClassName = 'no-scroll-and-hidden'
}: {
  overlayUniqueId: string;
  isActive: boolean;
  bodyClassName?: string;
}) => {
  const {
    overlays,
    setOverlays,
    setYOffset
  } = useOverlayContext();
  const overlayIsCurrentlyRendered = () => !!overlays.find((overlay: OverlayItem) => overlay.id === overlayUniqueId);
  const indexOfOverlay = () => overlays.findIndex((overlay: OverlayItem) => overlay.id === overlayUniqueId);
  const atLeastOneOverlayIsActive = () => overlays.some((overlay: OverlayItem) => overlay.isActive);
  const lockBodyElement = () => {
    const bodyAlreadyLocked = canUseDOM ? window.document.body.classList.contains(bodyClassName) : false;
    if (bodyAlreadyLocked) return;
    if (canUseDOM) {
      const {
        pageYOffset
      } = window;
      const pageOffsetAlreadySet = document.body.style.top && document.body.style.top !== '-0px';
      const documentTopValueAsNumber = Number(document.body.style.top.replace(/-/g, '').replace(/px/g, ''));
      const pageOffset = pageOffsetAlreadySet ? documentTopValueAsNumber : pageYOffset;
      setYOffset(pageOffset);
      document.body.classList.remove('no-scroll-and-hidden');
      document.body.classList.remove('no-scroll');
      document.body.classList.add(bodyClassName);
      document.body.style.top = `-${pageOffset}px`;
    }
  };
  const updateOverlayActiveState = (isActive: boolean) => {
    setOverlays((prevState: OverlayItem[]) => {
      const copy = [...prevState];
      copy.splice(indexOfOverlay(), 1, {
        id: overlayUniqueId,
        isActive
      });
      return copy;
    });
  };
  const addOverlayToCurrentlyRenderedOverlays = (isActive: boolean) => {
    setOverlays((prevState: OverlayItem[]) => {
      const copy = [...prevState];
      copy.push({
        id: overlayUniqueId,
        isActive
      });
      return copy;
    });
  };
  const removeOverlayFromCurrentlyRenderedOverlays = () => {
    setOverlays((prevState: OverlayItem[]) => {
      const copy = [...prevState];
      copy.splice(indexOfOverlay(), 1);
      return copy;
    });
  };
  useEffect(() => {
    if (overlayIsCurrentlyRendered()) {
      updateOverlayActiveState(isActive);
    } else {
      addOverlayToCurrentlyRenderedOverlays(isActive);
    }
  }, [isActive]);
  useEffect(() => {
    if (atLeastOneOverlayIsActive()) {
      lockBodyElement();
    }
  }, [overlays]);
  useEffect(() => {
    return () => {
      removeOverlayFromCurrentlyRenderedOverlays();
    };
  }, []);
};
export default useBodyLockWhenOverlayIsActive;